import React, { useEffect, useState } from "react";
import { commonInvoke } from "../networks/serviceCaller";
import { serviceUrls } from "../networks/serviceUrls";
import '../index.scss';
import { useDispatch } from "react-redux";
import { useSetLoader } from "../AppStore/loaderReducer";
import { useAccount } from "../AppStore/accountReducer";
import { regex4Email } from "../commLibs/constant";

const LoginModal = (props) => {
    const [needLogin, setneedLogin] = useState(false);
    const [isloginNotRegister, setisloginNotRegister] = useState(true);
    const [loaderCount, setLoader] = useSetLoader();
    const [accountState, accountDispatcher] = useAccount();
    // const accessToken = localStorage.getItem("accessToken");

    useEffect(() => {
        if(!accountState.accessToken) setneedLogin(true);
        else validateToken(accountState.accessToken);
        // console.log("account", accessToken, accountState, accountDispatcher);
    }, [accountState.accessToken]);


    const validateToken = ( JWToken ) => {
        const url = serviceUrls.validateToken;
        const jsonBody = {
            jwt: JWToken
        }

        setLoader(true)
        commonInvoke("POST", url, jsonBody)
        .then(res => {
            setneedLogin(false);
            console.log("validateToken res", res)
            const userObj = res?.data?.data;
            if(userObj) {
                accountDispatcher.setName(userObj.name)
                accountDispatcher.setEmailId(userObj.emailId)
                accountDispatcher.setUserId(userObj.userId)
                accountDispatcher.setAccessToken(JWToken)
            } else {
                throw new Error("Invalid token")
            }
        })
        .catch(err => {
            accountDispatcher.logOut();
            console.log("validateToken error", err)
        }).finally(() => setLoader(false))
    }

    return (
        <>
        {needLogin == true ?
        <div id="modal" >
            <div className="modal-overlay"></div>
            <div className="modal-content mt-5 align-middle">
                <div className='entryContainer w-100 bg-light'>
                    <div className="entryOption">
                        <a className={`font-weight-bold p-3 ${!isloginNotRegister ? 'bg-light text-dark' : 'bg-secondary text-white'}`}
                            style={{borderRadius: '25px 0 0 0'}} href="#signup" onClick={e => setisloginNotRegister(false)}>SIGN UP</a>
                        <a className={`font-weight-bold p-3 ${isloginNotRegister ? 'bg-light text-dark' : 'bg-secondary text-white'}`} 
                            style={{borderRadius: '0 25px 0 0'}} href="#login" onClick={e => setisloginNotRegister(true)}>LOG IN</a>
                    </div>
                    {/* // for signUp */}
                    {(!isloginNotRegister) && <SignUp setToLogin={setisloginNotRegister}/>}
                    {/* // for logIn */}
                    {(isloginNotRegister) && <LogIn/>}
                </div>
            </div>
        </div>
        : ""}
        </>
    )
}

export default LoginModal;

const LogIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loaderCount, setLoader] = useSetLoader();
    const [accountState, accountDispatcher] = useAccount();

    const userLogin = () => {
        if(isWrongEmail) return alert("Please Enter Valid Email")
        if(isWrongPassword) return alert("Please Enter Valid Password")

        const jsonBody = {
            emailId: email,
            password: password,
        }

        setLoader(true)
        commonInvoke("POST", serviceUrls.login, jsonBody)
        .then(res => {
            if(res?.data?.success != true) throw new Error("Login failed!") 
            console.log("loginres", res);
            localStorage.setItem("accessToken", res.data.jwt)
            accountDispatcher.setAccessToken(res.data.jwt)
            alert("Login successfull")
        })
        .catch(err => {
            console.log("loginerr", err);
            alert("Login failed")
        }).finally(() => setLoader(false))
    }

    const isWrongEmail = regex4Email.test(email) != true;
    const isWrongPassword = (password?.length <= 10 && password?.length >= 8) != true;

    return (
    <form>
        <div className='loginInput'>
            <label>Email Id</label>
            <input className={isWrongEmail ? `redOutLine` : ''} type="email" placeholder=' Enter Email ID ' value={email} onChange={e => {
                const _email = e.target.value;
                if(_email?.length <= 50) setEmail(_email)
            }} autoComplete="off"/>
        </div>
        <div className='loginInput'>
            <label>Password</label>
            <input className={isWrongPassword ? `redOutLine` : ''} type="password" placeholder=' Enter Password ' value={password} onChange={e => {
                const _password = e.target.value;
                if(_password.length <= 10) setPassword(_password)
            }} autoComplete="off"/>
        </div>
        <input className="font-weight-bold bg-secondary text-white border-0 p-2" type="button" value="LOGIN" onClick={userLogin}/>
    </form>
)}


const SignUp = (props) => {
    const [emailId, setemailId] = useState("");
    const [fullName, setfullName] = useState("");
    const [newPassword, setnewPassword] = useState("");
    const [confPassword, setconfPassword] = useState("");
    const [isCodeSent, setisCodeSent] = useState("");
    const [secretCode, setsecretCode] = useState("");
    const [loaderCount, setLoader] = useSetLoader();

    const handleGetCode = () => {
        if(isWrongEmail) return alert("Please Enter Valid Email")
        if(isWrongPassword) return alert("Please Enter Valid Password")
        if(isNotSamePassword) return alert("Confirm Password Not Matching")
        if(isWrongFullName) return alert("Please Enter Valid Full Name")

        const jsonData = {
            emailId: emailId,
            password: newPassword,
            name: fullName,
        }
        
        setLoader(true);
        commonInvoke("POST", serviceUrls.createUser, jsonData)
        .then(res => {
            if(!res.data.success) throw new Error("getcode failed!") 
            alert("Secret code sent to your email");
            setisCodeSent(true);
        })
        .catch(err => {
            console.log("getcode err", err)
            alert("Something went wrong please try again later");
        })
        .finally(() => setLoader(false));
    }

    const handleCodeSubmit = () => {
        if(isWrongSecretCode) return alert("Please Enter Valid Secret Code");

        const jsonData = {
            emailId: emailId,
            otp: secretCode,
        }

        setLoader(true);
        commonInvoke("POST", serviceUrls.verifyCode, jsonData)
        .then(res => {
            if(!res.data.success) throw new Error("codesubmit failed!") 
            alert("New account created successfully");
            props.setToLogin(true);
            window.location.replace('/#login');
        })
        .catch(err => {
            console.log("codesubmit err", err)
            alert("Something went wrong please try again later");
        })
        .finally(() => setLoader(false));
    }

    const handleValueChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        console.log("handleChangeValue ", inputName, "=", inputValue);

        switch(inputName) {
            case 'email': {
                if(inputValue?.length <= 50) setemailId(inputValue);
                break;
            }
            case 'fullName': {
                if(inputValue?.length <= 30) setfullName(inputValue);
                break;
            }
            case 'newPassword': {
                if(inputValue?.length <= 10) setnewPassword(inputValue);
                break
            }
            case 'confPassword': {
                setconfPassword(inputValue);
                break;
            }
            case 'secretCode': {
                if((inputValue == "" || /^\d+$/.test(inputValue)) && inputValue?.length <= 4) setsecretCode(inputValue);
                break;
            }
        }
    }

    const isWrongEmail = regex4Email.test(emailId) != true;
    const isWrongPassword = (newPassword?.length <= 10 && newPassword?.length >= 8) != true;
    const isNotSamePassword = (newPassword != confPassword);
    const isWrongFullName = (fullName?.length <= 30 && fullName?.length >= 3) != true;
    const isWrongSecretCode = (secretCode?.length == 4) != true;

    return (
    <div>
        {isCodeSent != true ? <form>
            <div className='loginInput'>
                <label>Email Id</label>
                <input className={isWrongEmail ? `redOutLine` : ''} value={emailId} type="email" name="email" placeholder=' Enter Email ID ' autoComplete="off"
                    onChange={handleValueChange}
                />
            </div>
            <div className='loginInput'>
                <label>Full Name</label>
                <input className={isWrongFullName ? `redOutLine text-capitalize` : 'text-capitalize'}
                    name="fullName"
                    value={fullName}
                    onChange={handleValueChange} type="text" placeholder=' Enter Full Name ' autoComplete="off"/>
            </div>
            <div className='loginInput'>
                <label>Create Password</label>
                <input 
                   value={newPassword} 
                   className={isWrongPassword ? `redOutLine` : ''}
                   name="newPassword"
                   onChange={handleValueChange} type="password" placeholder=' Enter Password ' autoComplete="off"/>
            </div>
            <div className='loginInput'>
                <label>Confirm Password</label>
                <input
                    value={confPassword}
                    className={isNotSamePassword ? `redOutLine` : ''}
                    name="confPassword"
                    onChange={handleValueChange} type="password" placeholder=' Confirm Password ' autoComplete="off"/>
            </div>
            <input className="font-weight-bold bg-secondary text-white border-0 p-2" type="button" value="GET CODE"
                onClick={handleGetCode}
            />
        </form> 
        :
        <form>
            <div className='loginInput'>
                <label>Code</label>
                <input 
                    value={secretCode}
                    className={isWrongSecretCode ? `redOutLine` : ''}
                    name="secretCode"
                    onChange={handleValueChange} type="number" placeholder=' Sent To Your Email' autoComplete="off"/>
            </div>
            <input className="font-weight-bold bg-secondary text-white border-0 p-2" type="button" value="SIGNUP"
                onClick={handleCodeSubmit} 
            />
        </form>}
    </div>
    )
}