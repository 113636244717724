import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const initialStateObject = {
    userId: "",
    name: "",
    emailId: "",
    socketId: "",
    accessToken: localStorage.getItem("accessToken"),
}

export const accountSlice = createSlice({
    name: 'account',
    initialState: initialStateObject,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload;
        },
        setEmailId: (state, action) => {
            state.emailId = action.payload;
        },
        setSocketId: (state, action) => {
            state.socketId = action.payload;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        logOut: (state) => {
            state = {...initialStateObject, accessToken: ""};
            return state; // need to return because assigning the whole state, not mutating the state
        }
    }
})

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;

export const useAccount = () => {
    const accountState = useSelector(state => state.account);
    const dispatch = useDispatch();
    const actionDispatcher = {};

    for(let [key, value] of Object.entries(accountActions)) {
        actionDispatcher[key] = ( payload ) => dispatch(value(payload))
    }

    return [accountState , actionDispatcher];
}