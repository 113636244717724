import React from "react";
import { useSelector } from "react-redux";
import { useSetLoader } from "../AppStore/loaderReducer";

export default function Loader () {
    const [loaderCount, setLoader] = useSetLoader();

    return (
        <>
        {loaderCount > 0 ? <div id="modal" className="loader-z-index" title="Loading...">
            <div className="modal-overlay"></div>
            <div className="modal-content mt-5 align-middle w-50">
                <div className="spinner-grow text-light" style={{width: '5rem', height: '5rem'}} role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div> : ""}
        </>
    )
}